@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"




























































































































































































































































.areas-performance

  &__title
    margin-bottom: 24px
    padding: 0

    +mq-m
      padding: 0 16px

  &__grid
    display: grid
    grid-template-columns: 1fr 1fr
    gap: 16px
    padding: 0 16px
    box-sizing: border-box

    +mq-s
      grid-template-columns: 100%
    +mq(425px)
      padding: 0

  &__card
    +mq-s
      border-radius: 0 !important

    .info-card__title
      color: $color-ink
      font-size: 18px

    .info-2:not(:first-child)
      margin-left: 32px
      align-items: flex-start

    .info-card__footer span
      color: $color-ink-light

      &:not(:first-child)
        margin-left: 24px
        +mq-s
